<template>
  <div>
    <b-modal
      id="fuel-Withdraw-modal"
      modal-class="modal-class"
      size="sm"
      hide-header
      hide-footer
      no-close-on-backdrop
      centered
    >
      <button class="close-btn" @click="hidefuelModal">
        <i class="fa fa-times"></i>
      </button>
      <template v-if="networkError !== null">
        <div class="row justify-content-center" style="color: red">
          {{ networkError }}
        </div>
      </template>
      <div class="row justify-content-center">
        <div class="d-flex flex-column align-items-center">
          <img class="settings" src="/media/rider/fuel_dollar.png" />
        </div>
      </div>
      <div class="row justify-content-center">
        <h3 class="text-fuel-withdrawl">
          Are you sure you want to pay<strong style="color: #46c864">
            PKR {{ riderDetails.balance.requested }}
          </strong>
          to {{ riderDetails.name }} right now?
        </h3>
      </div>
      <div class="row justify-content-center">
        <div class="cancel-box">
          <button v-on:click="hidefuelModal" class="btn button-cancel">
            Cancel
          </button>
        </div>
        <div class="pay-box">
          <button v-on:click="payamountOnOK" class="btn button-ok">
            Paynow
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "FuelWithdrawModal",
  data() {
    return {
      networkError: null
    };
  },
  props: {
    riderDetails: Object
  },
  mounted() {
    console.log("it is in fuel withdraw modal");
  },
  methods: {
    showfuelModal: function() {
      this.$bvModal.show("fuel-Withdraw-modal");
    },
    hidefuelModal: function() {
      this.$bvModal.hide("fuel-Withdraw-modal");
      this.$emit("close");
    },
    payamountOnOK: function() {
      console.log(
        "Paying " +
          this.riderDetails.balance.requested +
          " to " +
          this.riderDetails.name
      );

      let obj = {
        rider: this.riderDetails._id,
        amount: this.riderDetails.balance.requested
      };
      ApiService.post("/rider/clearRequested", obj)
        .then(({ data }) => {
          console.log(data.success);
          if (data.success) {
            this.$bvModal.hide("fuel-Withdraw-modal");
            this.$emit("close");
          } else if (data.error !== undefined) {
            this.networkError = data.error.message;
          }
        })
        .catch(error => {
          this.networkError = error;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .modal-class {
  .modal-dialog {
    min-width: 500px;
    .close-btn {
      background: none;
      position: absolute;
      right: 25px;
      top: 15px;
    }
    .settings {
      height: 140px;
      width: 140px;
      margin: 10px;
    }
    .cancel-box {
      margin-left: 20%;
      margin-right: 18px;
      margin-top: 20px;

      .button-cancel {
        background: #c6c6c6;
        color: #ffffff;
        font-weight: 700;
        font-size: 16px;
        padding-right: 30px;
        padding-left: 30px;
      }
    }
    .pay-box {
      margin-left: 18px;
      margin-right: 20%;
      margin-top: 20px;
      .button-ok {
        background: #46c864;
        color: #ffffff;
        font-weight: 700;
        font-size: 16px;
        padding-right: 30px;
        padding-left: 30px;
      }
    }
  }

  .text-fuel-withdrawl {
    justify-content: center;
    margin-left: 20%;
    margin-right: 20%;
    margin-top: 20px;
  }
}
</style>
