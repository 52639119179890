<template>
  <div class="d-flex validate-address">
    <b-form @submit="validateAddress">
      <b-form-input
        type="text"
        placeholder="Enter address"
        v-model="address"
        :class="validityLevel"
        required
      />
      <div class="d-flex justify-content-between">
        <span class="message">{{ message }}</span>
        <b-button
          class="verify-button"
          :class="processingRequest"
          type="submit"
          ref="addressFormSubmit"
          >Find
          <img class="ajax-loader" src="/media/ajax/white.gif" alt="Ajax loader"
        /></b-button>
      </div>
      <div class="address">{{ foundAddress }}</div>
    </b-form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "AddressInput",
  props: {
    addressPassed: String
  },
  data() {
    return {
      address: "",
      googleUrl: "https://maps.googleapis.com/maps/api/geocode/json",
      processingRequest: "",
      message: "",
      validityLevel: "",
      foundAddress: "",
      addressObject: {
        line1: "",
        line2: "",
        city: "",
        state: "",
        country: "",
        zipCode: "",
        address: ""
      }
    };
  },
  methods: {
    parentSubmittable: function() {
      const { line1, city, state, country, zipCode } = this.addressObject;
      if (
        line1 !== "" &&
        city !== "" &&
        state !== "" &&
        country !== "" &&
        zipCode !== ""
      ) {
        return true;
      }

      let t = this.address;
      this.address = "";
      this.$refs.addressFormSubmit.click();
      // this.$refs.addressForm.validateAddress();
      setTimeout(() => {
        this.address = t;
      }, 150);
      return false;
    },
    validateAddress: async function(e) {
      e.preventDefault();
      this.addressObject = {
        line1: "",
        line2: "",
        city: "",
        state: "",
        country: "",
        zipCode: "",
        address: "",
        lat: "",
        lng: "",
        placeId: ""
      };
      this.processingRequest = "processing";
      await axios
        .get(
          `${this.googleUrl}?key=${process.env.VUE_APP_GEOCODE_KEY}&address=${this.address}`
        )
        .then(({ data }) => {
          if (data.status !== "ZERO_RESULTS") {
            let result = data.results[0];

            if (result.geometry.location_type === "ROOFTOP") {
              this.message = "Please retry if this isn't the address!";
              this.foundAddress = result.formatted_address;
              this.validityLevel = "border-success";
            } else {
              this.message = "Please add street address as well!";
              this.foundAddress = result.formatted_address;
            }
            this.addressObject.address = result.formatted_address;
            this.addressObject.lat = result.geometry.location.lat;
            this.addressObject.lng = result.geometry.location.lng;
            this.addressObject.placeId = result.place_id;
            this.extractAddress(result.address_components);
          } else {
            this.message = "Address Not Found. Try Again!";
            this.validityLevel = "border-danger";
          }
        })
        .catch(() => {
          this.$emit("error", "Can't confirm address, please try again.");
        });
      this.processingRequest = "";
    },
    extractAddress(address) {
      let hasFields = [
        // Has zipcode
        false,
        // Has city
        false,
        // Has state
        false,
        // Has Country
        false,
        // Has line 1
        false
      ];
      let county = "";

      address.reverse().forEach(item => {
        if (item.types.includes("postal_code")) {
          this.addressObject.zipCode = item.long_name;
          hasFields[0] = true;
        } else if (item.types.includes("locality")) {
          this.addressObject.city = item.long_name;
          if (county !== "") {
            this.addressObject.city += ", " + county;
          }
          hasFields[1] = true;
        } else if (item.types.includes("administrative_area_level_1")) {
          this.addressObject.state = item.short_name;
          hasFields[2] = true;
        } else if (item.types.includes("administrative_area_level_2")) {
          county += item.short_name;
        } else if (item.types.includes("administrative_area_level_3")) {
          county = `, ${item.short_name}`;
        } else if (item.types.includes("administrative_area_level_4")) {
          county = `, ${item.short_name}`;
        } else if (item.types.includes("administrative_area_level_5")) {
          county = `, ${item.short_name}`;
        } else if (item.types.includes("country")) {
          this.addressObject.country = item.short_name;
          hasFields[3] = true;
        } else if (item.types.includes("neighborhood")) {
          this.addressObject.line2 += item.long_name;
        } else if (item.types.includes("route")) {
          this.addressObject.line1 += item.long_name;
        } else if (
          process.env.VUE_APP_REGION === "PAKISTAN" ||
          item.types.includes("street_number")
        ) {
          hasFields[4] = true;
          hasFields[0] = true;
          this.addressObject.line1 = `${item.long_name} ${this.addressObject.line1}`;
        }
      });

      if (hasFields.includes(false)) {
        this.validityLevel = "border-danger";
      } else {
        this.$emit("sendAddress", this.addressObject);
      }
    }
  },
  mounted() {
    this.address = this.addressPassed;
  }
};
</script>

<style lang="scss" scoped>
.validate-address {
  form {
    width: 100%;
    .verify-button {
      color: #fff;
      background: #dc1828;
      padding: 2px 10px;
      border-radius: 25px;
      display: flex;
      align-items: center;
      font-size: 12px;
      &:focus,
      &:hover {
        background: #dc1828;
        color: #fff;
      }
      &.processing {
        .ajax-loader {
          display: block;
        }
      }
      .ajax-loader {
        margin-left: 10px;
        height: 10px;
        width: auto;
        display: none;
      }
    }
    .message,
    .address {
      font-size: 10px;
    }
  }
}
</style>
