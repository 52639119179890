<template>
  <div>
    <b-modal
      id="rider-pass-modal"
      title="BootstrapVue"
      size="lg"
      hide-header
      hide-footer
      no-close-on-backdrop
      scrollable
      centered
      content-class="rider-pass-modal"
    >
      <span class="close-btn shadow-sm" @click="closeModal">
        <i class="fa fa-times"></i>
      </span>
      <h3 class="modal-title">New User</h3>
      <div v-if="region === 'PAKISTAN'" class="row password-container">
        <div class="password-heading">Auto Generated Password</div>
        <input
          type="hidden"
          id="secret-generated-password"
          :value="riderPass"
        />
        <div class="password-text shadow-sm">
          {{ this.riderPass }}
        </div>
      </div>
      <div v-if="region === 'US'" class="row password-container">
        <div class="password-heading">Auto Generated Password</div>
        <input type="hidden" id="secret-generated-password" :value="pass" />
        <div class="password-text shadow-sm">
          {{ this.pass }}
        </div>
      </div>
      <div class="row d-flex justify-content-center p-4">
        <b-button
          v-if="region === 'PAKISTAN'"
          variant="danger"
          v-clipboard:copy="riderPass"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          class="create-button"
          >Copy</b-button
        >
        <b-button
          v-if="region === 'US'"
          variant="danger"
          v-clipboard:copy="riderPass"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          class="create-button"
          >Copy</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    riderPass: String
  },
  data() {
    return { pass: this.riderPass };
  },
  computed: {
    ...mapState({
      region: state => state.region.isRegion
    })
  },
  watch: {
    riderPass: function(newVal) {
      this.pass = newVal;
    }
  },
  methods: {
    showModal: function() {
      this.$bvModal.show("rider-pass-modal");
    },
    closeModal: function() {
      this.$emit("updateRiders");
      this.$bvModal.hide("rider-pass-modal");
    },
    onCopy: function onCopy(/* e */) {
      // alert("You just copied: " + e.text);
    },
    onError: function onError(/* e */) {
      // alert("Failed to copy text");
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .modal-backdrop {
  background: #fff;
}
::v-deep .modal-dialog {
  width: 600px;
}
::v-deep .rider-pass-modal {
  background: #fff;
  position: relative;
  .close-btn {
    position: absolute;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    right: 15px;
    cursor: pointer;
    background: #aeacac;

    i {
      color: #fff;
      font-size: 14px;
    }
  }
  h3.modal-title {
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  .password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .password-heading {
      font-weight: 600;
      font-size: 18px;
    }
    .password-text {
      padding: 5px 20px;
      border: 1px solid #ccc;
    }
  }
}
</style>
